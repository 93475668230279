:root
    --main-font-family: 'Poppins', sans-serif

    --white: #FFFFFF
    

    --box-shadow-dropdown: 4px 10px 16px 0px rgba(0, 0, 0, 0.20)
    --bg-screen-container: #12161C
    --bg-button-primary: #242936
    --bg-button-active: #FF7E00
    --border-form: rgba(255, 255, 255, 0.1)
    --border-select: #1B1B1E

    // border-radius
    //2px    
    --border-radius-xxs: 0.125rem
    //4px
    --border-radius-xs: 0.25rem
    //8px
    --border-radius-s: 0.5rem
    //10px
    --border-radius-m: 0.625rem

    // box-shadow
    --box-shadow-header: 0 5px 10px rgb(0 0 0 / 25%)
