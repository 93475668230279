.custom-select
  position: relative
  border-bottom: 1px solid var(--border-form)
  &:hover
    border-bottom: 1px solid #2E8FF0
  select
    display: none
  
.select-selected
  position: relative
  padding: 1.25rem 0.25rem
  font-family: var(--main-font-family)
  font-style: normal
  font-weight: 200
  font-size: 1.25rem
  line-height: 1.2
  color: var(--white)
  opacity: 0.4
  cursor: pointer
  @include respond-to(tab-land)
    font-size: 1rem
    line-height: 1.5
    padding: 1.125rem 0.25rem
  @include respond-to(phone)
    font-size: 0.875rem
    line-height: 1.43
    padding: 0.75rem 0.25rem
.is-selected
  opacity: 1
        
.select-selected:after 
  position: absolute
  top: 30%
  right: 1%
  content: url('../../static/img/expand_down.png')
  display: inline-block
  width: 24px
  height: 24px
  transition: 0.2s

.select-selected.select-arrow-active:after 
  transform: rotate(180deg) translateY(-10%)

.select-items div
  cursor: pointer
  font-size: 1.25rem
  line-height: 1.2
  color: var(--white)
  padding: 1.25rem 1rem
  border-bottom: 1px solid var(--border-select)
  @include respond-to(tab-land)
    font-size: 1rem
    line-height: 1.5
  @include respond-to(phone)
    font-size: 0.875rem
    line-height: 1.43

.select-items 
  position: absolute
  background: #0F141B
  box-shadow: var(--box-shadow-dropdown)
  top: calc(100% + 0.125rem)
  left: 0
  right: 0
  z-index: 99
  width: calc(100% + 0.125rem)
  div
    font-weight: 200


.select-hide 
  display: none

.select-items div:hover,
.same-as-selected 
  background-color: var(--bg-button-primary)
