*,
*::after,
*::before
    margin: 0
    padding: 0
    box-sizing: inherit

body
    box-sizing: border-box // Moved it here and added inherit on the * selector
    background: var(--bg-screen-container)

::-webkit-scrollbar
    width: 0.75rem
    height: 0.5rem

::-webkit-scrollbar-track
    background: var(--background-scrollbar-track)

::-webkit-scrollbar-thumb
    background: var(--background-scrollbar-thumb)
    border-radius: 0.75rem

.container
    width: 100%
    max-width: 1522px
    margin: 0 auto
    padding: 0 5rem
    @include respond-to(big-desk)
      max-width: 992px
    @include respond-to(tab-land)
      padding: 0 1rem

    &.xl
      max-width: 1748px
      

a
    text-decoration: none

li
    list-style: none

input
    border: 0
    outline: none
