body
    font-family: var(--main-font-family)
    font-style: normal

h1
    font-weight: 400
    font-size: 5rem
    line-height: 6.25rem
    text-align: center
    color: var(--white)
    @include respond-to(big-desk)
      font-size: 3rem
      line-height: 1.5
    @include respond-to(phone)
      font-size: 2rem

h2
    font-weight: 400
    font-size: 4rem
    line-height: 4rem
    text-transform: uppercase
    color: var(--white)
    @include respond-to(big-desk)
      font-size: 2rem
      line-height: 1.5

h3
    font-weight: 400
    font-size: 2.5rem
    line-height: 4rem
    text-transform: uppercase
    color: #082B5F
    @include respond-to(big-desk)
      font-size: 1.75rem
      line-height: 1.5
    @include respond-to(phone)
      font-size: 1rem

h4
    font-weight: 400
    font-size: 1.5rem
    line-height: 2.5rem
    letter-spacing: 0.1em
    text-transform: uppercase
    color: var(--white)
h5
    font-weight: 400
    font-size: 1rem
    line-height: 1.75rem
    text-align: center
    letter-spacing: 0.1em
    text-transform: uppercase
    color: var(--white)

p
    font-weight: 200
    font-size: 1rem
    line-height: 1.75rem
    letter-spacing: 0.1em
    color: var(--white)

a
    color: var(--blue-light)
