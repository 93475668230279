@mixin respond-to($breakpoint)
    @if $breakpoint == phone-small
        @media only screen and (max-width: 25em)  
            @content  // 400px

    @if $breakpoint == phone
        @media only screen and (max-width: 37.5em)  
            @content  // 600px

    @if $breakpoint == tab-port-small
        @media only screen and (max-width: 48em)  
            @content  // 768px

    @if $breakpoint == tab-port
        @media only screen and (max-width: 56.25em)  
            @content  // 900px

    @if $breakpoint == tab-land
        @media only screen and (max-width: 75em)  
            @content  // 1200px

    @if $breakpoint == big-desk
        @media only screen and (max-width: 100em)  
            @content  // 1600px

    @if $breakpoint == ultra-big-desk
        @media only screen and (min-width: 156.25em)  
            @content  // 1800px
            
    @if $breakpoint == ultra-ultra-big-desk
        @media only screen and (min-width: 160.25em)  
            @content  // 2564px
            
    @if $breakpoint == ultra-ultra-ultra-big-desk
        @media only screen and (min-width: 225em)  
            @content  // 3600px

    