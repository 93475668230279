.screen-container
  position: relative
  height: 100vh

.logo
  display: block
  width: 100%
  cursor: pointer

.button button
  display: flex
  justify-content: center
  align-items: center
  gap: 0.625rem
  background: var(--bg-button-primary)
  border-radius: 3rem
  border: none
  padding: 1rem 3rem
  font-weight: 200
  font-size: 1.25rem
  line-height: 1.2
  color: var(--white)
  cursor: pointer
  transition: 0.2s
  &:hover
    background: var(--bg-button-active)


.bg-image
  position: fixed
  top: 0
  left: 0
  height: 100%
  width: 100%
  z-index: -1
  overflow: hidden
  &.visible
    overflow: visible
  &.full-width-height
    img
      width: 100%
      height: 100%
      object-fit: cover
  &.half-width
    width: 55%

.notification
  position: absolute
  bottom: 0
  top:0
  left: 0
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 1.5rem
  pointer-events: none
  opacity: 0
  transition: 1s
  h4 
    font-weight: 200
    font-size: 2.5rem
    line-height: 1.2
    text-transform: uppercase
    @include respond-to(phone)
      font-size: 1.75rem
  p
    font-weight: 200
    font-size: 1.5rem
    line-height: 1.33
    opacity: 0.8
    @include respond-to(phone)
      font-size: 1rem
  &.show
    opacity: 1 !important
    pointer-events: auto

.contacts-us__wrap .form .form__contact-us .empty-field
  border-bottom: 1px solid var(--bg-button-active)

