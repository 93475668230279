.section__contacts-us
  position: relative
  display: flex
  flex-direction: column
  min-height: 100%
  gap: 2rem
  padding: 1rem 0px
  @include respond-to(big-desk)
    padding: 1rem 0 3rem
    gap: 1rem

  @include respond-to(phone)
    justify-content: start
    padding: 0 0 2rem
    gap: 0

.contacts-us__logo
  img
    width: 7.5rem
  @include respond-to(tab-land)
    text-align: center
    img
      width: 7.26rem
      @include respond-to(phone)
        width: 6.38rem

.contacts-us__wrap
  display: flex
  gap: 1rem
  flex-grow: 1
  align-items: center
  @include respond-to(tab-land)
    flex-direction: column
    gap: 2rem
    align-items: center
    justify-content: space-between
  @include respond-to(phone)
    gap: 1rem

  .promo
    display: flex
    flex-direction: column
    flex: 1 1 50%
    padding-right: 6rem
    @include respond-to(tab-land)
      flex: 1 1 auto
      padding-right: 0
      justify-content: center
    @include respond-to(phone)
      flex-direction: row

  .promo__content
    display: flex
    flex-direction: column
    align-self: center
    justify-content: center
    height: 100%
    @include respond-to(tab-land)
      margin-left: 0
      align-items: center
    h1
      font-weight: 200
      font-size: 5rem
      line-height: 105%
      text-align: start
      margin-bottom: 2rem
      max-width: 47.5rem
      @include respond-to(big-desk)
        font-size: 3.5rem
        max-width: 40.5rem
      @include respond-to(tab-land)
        text-align: center
        font-size: 3rem
        margin-bottom: 1.563rem
        max-width: 40rem
      @include respond-to(phone)
        font-size: 2rem
        max-width: 23rem
    p
      font-weight: 200
      font-size: 1.5rem
      line-height: 150%
      letter-spacing: 0.2
      opacity: 0.7
      max-width: 41rem
      @include respond-to(tab-land)
        font-size: 1.25rem
        opacity: 0.6
        max-width: 30rem
        text-align: center
      @include respond-to(phone)
        font-size: 0.9rem
        max-width: 20rem

  .form
    position: relative
    display: flex
    justify-content: center
    color: #FFFFFF
    padding-right: 1.8rem
    @include respond-to(tab-land)
      flex: 1 1 auto
      margin: 0
      padding-right: 0

    .form__wrap
      position: relative
      width: 32.5rem
      padding: 6rem 0 5rem
      @include respond-to(big-desk)
        padding: 0
        width: 28rem
      .bg-image.visible
        img
          position: absolute
          bottom: -1.5rem
          right: 0
          width: 170%
          @include respond-to(phone)
            bottom: 0
            width: 169%
            height: 170%

      @include respond-to(tab-land)
        padding: 1.5rem 3.75rem
        width: 37.5rem
      @include respond-to(phone)
        padding: 2rem 1.5rem
        width: 21.438rem

    .form__contact-us
      display: flex
      flex-direction: column
      gap: 2.5rem
      opacity: 1
      transition: 0.4s
      @include respond-to(big-desk)
        gap: 1.75rem
      @include respond-to(phone)
        gap: 1.5rem
      p
        font-weight: 400
        font-size: 1.25rem
        line-height: 1.2
        letter-spacing: 0.2
        @include respond-to(tab-land)
          font-size: 1rem
          line-height: 1.5
        @include respond-to(phone)
          font-size: 0.875rem
          line-height: 1.43

      input
        width: 100%
        border-bottom: 1px solid var(--border-form)
        background: none
        padding: 1.25rem 0.25rem
        font-family: var(--main-font-family)
        font-style: normal
        font-weight: 200
        font-size: 1.25rem
        line-height: 1.2
        color: var(--white)
        &:-webkit-autofill
          background-color: transparent !important
          -webkit-text-fill-color: var(--white) !important
          color: var(--white)
          -webkit-background-clip: text !important
          background-clip: text !important
        @include respond-to(tab-land)
          padding: 1.125rem 0.25rem
          font-size: 1rem
          line-height: 1.5
        @include respond-to(phone)
          padding: 0.875rem 0.25rem
          font-size: 0.875rem
          line-height: 1.43
      .custom-select
        p
          margin-bottom: 0.5rem
      textarea
        height: 4rem
        background-color: transparent
        border: none
        resize: none
        outline: none
        border-bottom: 1px solid var(--border-form)
        padding: 1.25rem 0.25rem
        font-family: var(--main-font-family)
        font-style: normal
        font-weight: 200
        font-size: 1.25rem
        line-height: 1.2
        color: var(--white)
        @include respond-to(tab-land)
          height: 3.75rem
          padding: 1.125rem 0.25rem
          font-size: 1rem
          line-height: 1.5
        @include respond-to(phone)
          height: 3rem
          padding: 0.875rem 0.25rem
          font-size: 0.875rem
          line-height: 1.43
      &.hidden
        opacity: 0
    .form-box__button
      margin-top: 1rem
      @include respond-to(tab-land)
        margin-top: 1rem
        button
          font-size: 1rem
          line-height: 1.5
      @include respond-to(phone)
        margin-top: 0.5rem
        button
          width: 100%
          font-size: 0.875rem
          line-height: 1.43
      img
        width: 20px
        height: 20px
    

.contacts-us__email
  @include respond-to(tab-land)
    margin: 3rem auto 0
  @include respond-to(phone)
    margin-top: 2.5rem
  a
    display: flex
    align-items: center
    gap: 0.5rem
    cursor: pointer
    @include respond-to(tab-land)
      gap: 0.75rem
    img
      width: 32px
      height: 32px
    @include respond-to(phone)
      gap: 0.5rem
      img
        width: 24px
        height: 24px
    p
      font-weight: 200
      font-size: 1.25rem
      line-height: 1.6
      @include respond-to(tab-land)
        font-size: 1.25rem
        line-height: 1.6
      @include respond-to(phone)
        font-size: 0.875rem
        line-height: 1.43
